import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function RefBibliografica() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Texto, setTexto] = useState('');

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setTexto(Model.Texto)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setTexto('')
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarReferenciasBibliograficas'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };


  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoReferenciasBibliograficas',
      data: {
        Texto
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistros()
          Limpar()
          message.success('Glossário criado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao criar glossário')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarReferenciasBibliograficas',
      data: {
        Id: Id,
        Texto
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Glossário editado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar glossário')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarReferenciasBibliograficas',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Glossário excluído com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistros()
          message.success('Falha ao excluir glossário')
        }
      })
  };


  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'TEXTO',
      dataIndex: 'Texto',
      key: 'Texto',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          {/* <Search
            style={{ marginLeft: 10, width: 400 }}
            placeholder="Buscar"
            enterButton="Buscar"
            size="large"
            onSearch={onSearch}
          /> */}
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Registros} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Ref. Bibliográfica" : "Nova Ref. Bibliográfica"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Texto</label>
            <Input.TextArea value={Texto} onChange={(e) => setTexto(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Texto" />
          </div>
        </div>

      </Drawer>

    </div>
  );
};



export default RefBibliografica;
