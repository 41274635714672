import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Notificacoes() {

  const [LoadingPage, setLoadingPage] = useState(false);
  const [Titulo, setTitulo] = useState('');
  const [Mensagem, setMensagem] = useState('');
  const [Clientes, setClientes] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  async function BuscarClientes() {
    setLoadingPage(true)
    axios({
      method: 'post',
      url: Config.Url + 'ListarUsuarioComFiltro',
      data: { Buscar: `%%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false)
          setClientes(e.result);
        } else {
          setLoadingPage(false)
          setClientes([]);
        }
      })
  };

  useEffect(() => {
    BuscarClientes();
  }, [])

  async function DisparaNotificao(Model) {
    const Message = {
      "to": Model.TokenNotificacao,
      "sound": "default",
      "title": Titulo,
      "body": Mensagem
    };

    fetch("https://servidor.acupunturapro.com/Notification", {
      method: 'POST',
      body: JSON.stringify(Message),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(e => e.json())
      .then(e => {

        if (e.erro === true) {
          setTitulo('');
          setMensagem('');
        } else {
          alert('Falha ao mandar notificações.');
        };

      });

  };


  async function Send() {
    api.destroy(1)
    Clientes.filter(e => e.TokenNotificacao !== null && e.TokenNotificacao !== '')
      .map(e => {
        DisparaNotificao(e);
      });
  };

  function Mensagemdd() {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(1)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Send()} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja disparar esta notificação agora ?',
      btn,
      key: 1
    });
  }

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <Progress strokeColor={LoadingPage === null ? "orange" : "green"} percent={LoadingPage ? 0 : LoadingPage === null ? 50 : 100} />
      <div className='ContainerTable'>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 50, marginBottom: 10 }}>
          <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
            <Input value={Titulo} onChange={(e) => setTitulo(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Titulo" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 50, marginBottom: 10 }}>
          <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
            <Input.TextArea value={Mensagem} autoSize={{ minRows: 15, maxRows: 15 }} onChange={(e) => setMensagem(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Mensagem" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 50, marginBottom: 10 }}>
          <div style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
            <Button onClick={Mensagemdd} type="primary" icon={<SendOutlined />} size='large'>
              Enviar
            </Button>
          </div>
        </div>

      </div>
    </div>
  );
};



export default Notificacoes;
