import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress, Switch } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Configuracoes() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Producao_android, setProducao_android] = useState(false);
  const [Producao_ios, setProducao_ios] = useState(false);
  const [Versao_android, setVersao_android] = useState('');
  const [Versao_ios, setVersao_ios] = useState('');
  const [Manutencao, setManutencao] = useState(false);

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setProducao_android(Model.Producao_android)
    setProducao_ios(Model.Producao_ios)
    setVersao_android(Model.Versao_android)
    setVersao_ios(Model.Versao_ios)
    setManutencao(Model.Manutencao)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setProducao_android('')
    setProducao_ios('')
    setVersao_android('')
    setVersao_ios('')
    setManutencao('')
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarConfiguracoes'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'UpdateConfiguracoes',
      data: {
        Id: Id,
        Producao_android,
        Producao_ios,
        Versao_android,
        Versao_ios,
        Manutencao
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Glossário editado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar glossário')
        }
      })
  };


  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  const columns = [
    {
      title: 'APP ANDROID EM PRODUÇÃO',
      dataIndex: 'Producao_android',
      key: 'Producao_android',
      render: (dataIndex, key) =>
        <text>{dataIndex === true ? 'Sim' : 'Não'}</text>
    },
    {
      title: 'APP IOS EM PRODUÇÃO',
      dataIndex: 'Producao_ios',
      key: 'Producao_ios',
      render: (dataIndex, key) =>
        <text>{dataIndex === true ? 'Sim' : 'Não'}</text>
    },
    {
      title: 'VERSÃO DO APP ANDROID',
      dataIndex: 'Versao_android',
      key: 'Versao_android',
    },
    {
      title: 'VERSÃO DO APP IOS',
      dataIndex: 'Versao_ios',
      key: 'Versao_ios',
    },
    {
      title: 'EM MANUTENÇÃO',
      dataIndex: 'Manutencao',
      key: 'Manutencao',
      render: (dataIndex, key) =>
        <text>{dataIndex === true ? 'Sim' : 'Não'}</text>
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Registros} max columns={columns} />
      </div>

      <Drawer
        title='Editar configurações - (CUIDADO)'
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Editar()} >Salvar</Button>
          </Space>
        }>
        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>App Android em Produção</label>
            <Switch defaultChecked={Producao_android} onChange={() => setProducao_android(!Producao_android)} />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>App Ios em Produção</label>
            <Switch defaultChecked={Producao_ios} onChange={() => setProducao_ios(!Producao_ios)} />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Versao android</label>
            <Input value={Versao_android} onChange={(e) => setVersao_android(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Texto" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Versao ios</label>
            <Input value={Versao_ios} onChange={(e) => setVersao_ios(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Texto" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>App em manutenção</label>
            <Switch defaultChecked={Manutencao} onChange={() => setManutencao(!Manutencao)} />
          </div>
        </div>

      </Drawer>

    </div>
  );
};



export default Configuracoes;
