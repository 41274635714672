import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress, Avatar, Upload, Image } from 'antd';
import { DeleteOutlined,InboxOutlined,FilePdfOutlined, EyeOutlined,LoadingOutlined, PlusOutlined, } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Biblioteca() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Livro, setLivro] = useState('');
  const [Descricao, setDescricao] = useState('');
  const [Capa, setCapa] = useState('');
  const [Link, setLink] = useState('');
  const [Autor, setAutor] = useState('');
  const [Escritora, setEscritora] = useState('');

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setLivro(Model.Livro)
    setDescricao(Model.Descricao)
    setCapa(Model.Capa)
    setLink(Model.Link)
    setAutor(Model.Autor)
    setEscritora(Model.Escritora)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setLivro('')
    setDescricao('')
    setCapa('')
    setLink('')
    setAutor('')
    setEscritora('')
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarBibliotecaComFiltro',
      data:{Buscar:`%${Buscar}%`}
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };


  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoBiblioteca',
      data: {
        Livro: Livro,
        Descricao: Descricao,
        Capa: Capa,
        Link: Link,
        Autor: Autor,
        Escritora: Escritora
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistros()
          Limpar()
          message.success('Livro criado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao criar livro')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarBiblioteca',
      data: {
        Id: Id,
        Livro: Livro,
        Descricao: Descricao,
        Capa: Capa,
        Link: Link,
        Autor: Autor,
        Escritora: Escritora
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Livro editado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar livro')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarBiblioteca',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Livro excluído com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistros()
          message.success('Falha ao excluir livro')
        }
      })
  };

  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'CAPA',
      dataIndex: 'Capa',
      key: 'Capa',
      render: (dataIndex,) =>
      <di>
        <Avatar size={60} src={<Image style={{ width: 60, height: 60, borderRadius: 60 }} src={dataIndex === '' ? require('./../../Images/semimagem.png') : Config.Url + 'CapasLivros/' + dataIndex} />} />
      </di>
    },
    {
      title: 'LIVRO',
      dataIndex: 'Livro',
      key: 'Livro',
    },
    {
      title: 'LINK',
      dataIndex: 'Link',
      key: 'Link',
      render: (dataIndex,) =>
      <di>
        <Button href={Config.Url + 'Livros/' + dataIndex} target='_blank' icon={<FilePdfOutlined />}/>
      </di>
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  const uploadButton = (
    <div style={{ width: 450, height: 80, marginBottom: 10, backgroundColor: '#eee', justifyContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
      {Loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Capa
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      setCapa(info.file.name);
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    action: Config.Url + 'UploadsLivros',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setLink(info.file.name)
        message.success(`${info.file.name} teve sucesso ao subir para o servidor.`);
      } else if (status === 'error') {
        setLink('')
        message.error(`${info.file.name} falhou ao subir para o servidor.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <Search
            style={{ marginLeft: 10, width: 400 }}
            placeholder="Buscar"
            enterButton="Buscar"
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Registros} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Livro" : "Novo Livro"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Livro</label>
            <Input value={Livro} onChange={(e) => setLivro(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Livro" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Descrição</label>
            <Input.TextArea value={Descricao} onChange={(e) => setDescricao(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Descrição" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Autor</label>
            <Input value={Autor} onChange={(e) => setAutor(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Autor" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Escritora</label>
            <Input value={Escritora} onChange={(e) => setEscritora(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Escritora" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Upload
            name="file"
            listType="picture"
            className="avatar-uploader"
            action={Config.Url + 'UploadsCapas'}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {Capa ? (
              <Avatar  
                src={Config.Url + 'CapasLivros/' + Capa}
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: 10,
                  marginBottom: 15
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>

        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Upload.Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click aqui para {Id? "editar":"adicionar"} o pdf do livro</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other
              banned files.
            </p>
          </Upload.Dragger>
        </div>

       

      </Drawer>

    </div>
  );
};






export default Biblioteca;
