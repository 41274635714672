import { useState } from "react";
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';


function Funcoes() {
    const [Usuario, setUsuario] = useState("");
    const [Senha, setSenha] = useState("");
    const [Load, setLoad] = useState(false);
    const [Security, setSecurity] = useState(false);
    const navigate = useNavigate();

    function Munsagens(params) {
        if (params === 'Sucesso') {
            message.open({
                type: 'success',
                content: 'Login efetuado com sucesso.',
            });
            // navigate('/');
          window.location.reload(false);
        } else if (params === 'Senha') {
            message.open({
                type: 'info',
                content: 'Senha incorreta.',
            });
        } else {
            message.open({
                type: 'error',
                content: params,
            });
        };
    };

    async function Logar() {
        setLoad(true);
        if (Usuario === "") {
            message.open({
                type: 'info',
                content: 'Informe um usuário.',
            });
            setLoad(false);
            return;
        }
        if (Senha === "") {
            message.open({
                type: 'info',
                content: 'Informe sua Senha.',
            });
            setLoad(false);
            return;
        }

        if(Usuario === "wanderadministrativo" && Senha === "@AcupunturaProCmteWander01"){
            setTimeout(() => {
                setLoad(false);
                Munsagens("Sucesso");
                localStorage.setItem('$f5e1fe1fef5e1f6e51fe','true')
            }, 2500);
        }else{
            setLoad(false);
            localStorage.setItem('$f5e1fe1fef5e1f6e51fe','false')
            Munsagens('Sem acesso para utilização');
        }

    };



    return { Load, Logar, Usuario, setUsuario, Senha, setSenha, Security, setSecurity };
};

export default Funcoes;