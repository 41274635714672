import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, Avatar, Select, Image, Upload, message, Modal, Progress } from 'antd';
import { DeleteOutlined, LoadingOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { cpf as CPFValid } from 'cpf-cnpj-validator';
import Icon from '@mdi/react';
import { mdiCashRegister, mdiCashMinus, mdiCashPlus, mdiCashRemove, mdiClipboardList } from '@mdi/js';
import './style.css';
const { Search } = Input;
const moment = require('moment-timezone');

function Mapas() {
  const [api, contextHolder] = notification.useNotification();
  const [Loading, setLoading] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [MapasJson, setMapas] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Tecnica, setTecnica] = useState('');


  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setTecnica(Model.Tecnica)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setTecnica('')
  };

  async function BuscarMapas() {
    setLoadingPage(true)
    axios({
      method: 'post',
      url: Config.Url + 'ListarMapasAll',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false)
          setMapas(e.result);
        } else {
          setLoadingPage(false)
          setMapas([]);
        }
      })
  };

  async function Salvar() {
    axios({
      method: 'post',
      url: Config.Url + 'NovoMapas',
      data: {
        Tecnica:Tecnica       
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarMapas()
          Limpar()
          message.success('Meridiano criado com sucesso')
        } else {
          BuscarMapas()
          message.success('Falha ao criar meridiano')
        }
      });
  };

  async function Editar() {
    axios({
      method: 'put',
      url: Config.Url + 'EditarMapas',
      data: {
        Id:Id,
        Tecnica:Tecnica       
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarMapas()
          Limpar()
          message.success('Meridiano editado com sucesso')
        } else {
          BuscarMapas()
          message.success('Falha ao editar meridiano')
        }
      })
  };

  async function Deletar(Model) {
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarMapas',
      data: {
        Id:Model.Id
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarMapas()
          Limpar()
          message.success('Meridiano excluído com sucesso')
        } else {
          BuscarMapas()
          message.success('Falha ao excluir meridiano')
        }
      })
  };

  useEffect(() => {
    BuscarMapas();
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'TÉCNICA',
      dataIndex: 'Tecnica',
      key: 'Tecnica',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];
  
  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <Search
          style={{ marginLeft: 10, width: 400 }}
          placeholder="Buscar por nome"
          enterButton="Buscar"
          size="large"
          onSearch={onSearch}
        />
      </div>
      <Progress  percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={MapasJson} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar mapa" : "Novo mapa"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar():Salvar()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Técnica</label>
            <Input value={Tecnica} onChange={(e)=>setTecnica(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Tecnica" />
          </div>
        </div>

      </Drawer>

    </div>
  );
};



export default Mapas;
