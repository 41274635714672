import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress, Select } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Sintomas() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [NovoTratamento, setNovoTratamento] = useState(false);
  const [Auxiliares, setAuxiliares] = useState([]);
  const [Sintomas, setSintomas] = useState([]);
  const [Tratamentos, setTratamentos] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const [AuxiliarId, setAuxiliarId] = useState('');
  const onSearch = (value) => setBuscar(value);

  const SintomasFilter = AuxiliarId === "" ? Sintomas : Sintomas.filter(e => e.Auxiliar === AuxiliarId);

  const [IdTratamento, setIdTratamento] = useState('');
  const [Id, setId] = useState('');
  const [Nome, setNome] = useState('');
  const [Auxiliar, setAuxiliar] = useState('');
  const [Tratamento, setTratamento] = useState('');


  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setNome(Model.Nome)
    setAuxiliar(Model.Auxiliar)
  };

  function AbrirRegistroTratamento(Model) {
    setNovoTratamento(true)
    setIdTratamento(Model.Id)
    setTratamento(Model.Tratamento)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setNome('')
    setAuxiliar('')
  };

  function LimparTratamento() {
    setNovoTratamento(false)
    setIdTratamento('')
    setTratamento('')
  };

  async function BuscarAuxiliares() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarAuxiliarFiltro',
      data: { Buscar: `%%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setAuxiliares(e.result);
        } else {
          setAuxiliares([]);
        }
      })
  };

  async function BuscarTratamentos() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarTratamentos'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setTratamentos(e.result);
          if(Id){
          setLoadingPage(false);
          }
        } else {
          setTratamentos([]);
          if(Id){
            setLoadingPage(false);
            }
        }
      })
  };



  async function BuscarSintomas() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarSintomasFiltro',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setSintomas(e.result);
        } else {
          setLoadingPage(false);
          setSintomas([]);
        }
      })
  };


  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'CriarSintomas',
      data: {
        Nome: Nome,
        Auxiliar: Auxiliar
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarSintomas()
          Limpar()
          message.success('Auxiliar criado com sucesso')
        } else {
          BuscarSintomas()
          setLoadingPage(false);
          message.success('Falha ao criar auxiliar')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarSintomas',
      data: {
        Id: Id,
        Nome: Nome,
        Auxiliar: Auxiliar
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarSintomas()
          message.success('Auxiliar editado com sucesso')
        } else {
          BuscarSintomas()
          setLoadingPage(false);
          message.success('Falha ao editar auxiliar')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'ExcluirSintomas',
      data: {
        Id: Model.Id
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarSintomas()
          message.success('Auxiliar excluído com sucesso')
        } else {
          setLoadingPage(false);
          BuscarSintomas()
          message.success('Falha ao excluir auxiliar')
        }
      })
  };

  async function SalvarTratamento() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'CriarTratamentos',
      data: {
        Tratamento:Tratamento,
        Sintoma: Id
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarTratamentos()
          LimparTratamento()
          message.success('Tratamento criado com sucesso')
        } else {
          BuscarTratamentos()
          message.success('Falha ao criar tratamento')
        }
      });
  };

  async function EditarTratamento() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarTratamentos',
      data: {
        Id: IdTratamento,
        Tratamento:Tratamento
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          LimparTratamento()
          BuscarTratamentos()
          message.success('Tratamento editado com sucesso')
        } else {
          BuscarTratamentos()
          message.success('Falha ao editar tratamento')
        }
      })
  };

  async function DeletarTratamento(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'ExcluirTratamentos',
      data: {
        Id: Model.Id
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          LimparTratamento()
          BuscarTratamentos()
          message.success('Tratamento excluído com sucesso')
        } else {
          BuscarTratamentos()
          message.success('Falha ao excluir tratamento')
        }
      })
  };


  useEffect(() => {
    BuscarSintomas()
  }, [Buscar])

  useEffect(() => {
    BuscarTratamentos()
    BuscarAuxiliares()
  }, [])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }

  function MensagemPedirSenhaTratamento(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => DeletarTratamento(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }

  function FiltrarNome(Id) {
    const res = Auxiliares.filter(e => e.Id === Id)
    return res.map(e => e.Nome).toString()
  }


  const columns = [
    {
      title: 'AUXILIAR',
      dataIndex: 'Auxiliar',
      key: 'Auxiliar',
      render: (dataIndex, key) =>
        <text>{FiltrarNome(dataIndex)}</text>
    },
    {
      title: 'NOME',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  const columnsTratamentos = [
    {
      title: 'TRATAMENTO',
      dataIndex: 'Tratamento',
      key: 'Tratamento',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistroTratamento(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenhaTratamento(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ]

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <div>
            <Select onSelect={(e) => setAuxiliarId(e)} size='large' style={{ width: 200 }} placeholder="Auxiliares">
              <option value="" >Todos</option>
              {Auxiliares.map((e, index) => (
                <option key={index} value={e.Id} >{e.Nome}</option>
              ))}
            </Select>
            <Search
              style={{ marginLeft: 10, width: 400 }}
              placeholder="Buscar por nome"
              enterButton="Buscar"
              size="large"
              onSearch={onSearch}
            />
          </div>
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={SintomasFilter} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Sintoma" : "Novo Sintoma"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Auxiliar</label>
            <Select value={Auxiliar} onSelect={(e) => setAuxiliar(e)} size='large' style={{ width: '100%' }} placeholder="Auxiliares">
              <option value="" >Auxiliar</option>
              {Auxiliares.map((e, index) => (
                <option key={index} value={e.Id} >{e.Nome}</option>
              ))}
            </Select>
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Sintoma</label>
            <Input value={Nome} onChange={(e) => setNome(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Sintoma" />
          </div>
        </div>

        {Id && <div className='ContainerTableNav'>
          <text>Tratamentos disponíveis</text>
          <Space>
            <Button style={{ height: 30 }} type="primary" onClick={() => setNovoTratamento(true)}>ADICIONAR</Button>
          </Space>
        </div>}

        {Id && <Table dataSource={Tratamentos.filter(e => e.Sintoma === Id)} max columns={columnsTratamentos} />}

      </Drawer>

      <Drawer
        title={IdTratamento !== '' ? "Editar Tratamento" : "Novo Tratamento"}
        closable={false}
        width={480}
        placement="right"
        open={NovoTratamento}
        extra={
          <Space>
            <Button onClick={() => [setNovoTratamento(false), LimparTratamento()]}>Fechar</Button>
            <Button type="primary" onClick={() => IdTratamento !== '' ? EditarTratamento() : SalvarTratamento()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Tratamento</label>
            <Input value={Tratamento} onChange={(e) => setTratamento(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Tratamento" />
          </div>
        </div>

      </Drawer>

    </div>
  );
};



export default Sintomas;
