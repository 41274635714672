import { useState, useEffect } from 'react'
import Config from '../../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Plantasteraputicas() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Enfermidades, setEnfermidades] = useState('');
  const [Indecacoes, setIndecacoes] = useState('');

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setEnfermidades(Model.Enfermidades)
    setIndecacoes(Model.Indecacoes)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setEnfermidades('')
    setIndecacoes('')
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarPlantasComFiltro',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };


  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoPlantas',
      data: {
        Enfermidades,
         Indecacoes
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistros()
          Limpar()
          message.success('Planta criada com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao criar planta')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarPlantas',
      data: {
        Id: Id,
        Enfermidades,
         Indecacoes
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Planta editada com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar planta')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarPlantas',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Planta excluída com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistros()
          message.success('Falha ao excluir planta')
        }
      })
  };


  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'ENFERMIDADE',
      dataIndex: 'Enfermidades',
      key: 'Enfermidades',
    },
    {
      title: 'INDICAÇÕES',
      dataIndex: 'Indecacoes',
      key: 'Indecacoes',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <Search
            style={{ marginLeft: 10, width: 400 }}
            placeholder="Buscar"
            enterButton="Buscar"
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Registros} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Floral" : "Novo Floral"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>


        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Enfermidades</label>
            <Input value={Enfermidades} onChange={(e) => setEnfermidades(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Enfermidades" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Indicações</label>
            <Input.TextArea value={Indecacoes} onChange={(e) => setIndecacoes(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Indicações" />
          </div>
        </div>

      </Drawer>

    </div>
  );
};



export default Plantasteraputicas;
