import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, Avatar, Select, Image, Upload, message, Modal, Progress } from 'antd';
import { DeleteOutlined, LoadingOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { cpf as CPFValid } from 'cpf-cnpj-validator';
import Icon from '@mdi/react';
import { mdiCashRegister, mdiCashMinus, mdiCashPlus, mdiCashRemove, mdiClipboardList } from '@mdi/js';
import './style.css';
const { Search } = Input;
const moment = require('moment-timezone');

function Meridianos() {
  const [api, contextHolder] = notification.useNotification();
  const [Loading, setLoading] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Meridianos, setMeridianos] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Nome, setNome] = useState('');
  const [Tipo, setTipo] = useState('');
  const [Hora, setHora] = useState('');
  const [Imagem, setImagem] = useState('');
  const [Ordem, setOrdem] = useState('');


  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setNome(Model.Nome)
    setTipo(Model.Tipo)
    setHora(Model.Hora)
    setImagem(Model.Imagem)
    setOrdem(Model.Ordem)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setNome('')
    setTipo('')
    setHora('')
    setImagem('')
    setOrdem('')
  };

  async function BuscarMeridianos() {
    setLoadingPage(true)
    axios({
      method: 'post',
      url: Config.Url + 'ListarMeridianosFiltro',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false)
          setMeridianos(e.result);
        } else {
          setLoadingPage(false)
          setMeridianos([]);
        }
      })
  };

  async function Salvar() {
    axios({
      method: 'post',
      url: Config.Url + 'NovoMeridianos',
      data: {
        Nome:Nome,
        Tipo:Tipo,
        Hora:Hora,
        Imagem:Imagem,
        Ordem:Ordem
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarMeridianos()
          Limpar()
          message.success('Meridiano criado com sucesso')
        } else {
          BuscarMeridianos()
          message.success('Falha ao criar meridiano')
        }
      });
  };

  async function Editar() {
    axios({
      method: 'put',
      url: Config.Url + 'EditarMeridianos',
      data: {
        Id:Id,
        Nome:Nome,
        Tipo:Tipo,
        Hora:Hora,
        Imagem:Imagem,
        Ordem:Ordem
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarMeridianos()
          Limpar()
          message.success('Meridiano editado com sucesso')
        } else {
          BuscarMeridianos()
          message.success('Falha ao editar meridiano')
        }
      })
  };

  async function Deletar(Model) {
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarMeridianos',
      data: {
        Id:Model.Id
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarMeridianos()
          Limpar()
          message.success('Meridiano excluído com sucesso')
        } else {
          BuscarMeridianos()
          message.success('Falha ao excluir meridiano')
        }
      })
  };

  useEffect(() => {
    BuscarMeridianos();
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'ORDEM',
      dataIndex: 'Ordem',
      key: 'Ordem',
    },
    {
      title: 'IMAGEM',
      dataIndex: 'Imagem',
      key: 'Imagem',
      render: (dataIndex,) =>
        <di>
          <Avatar size={60} src={<Image style={{ width: 60, height: 60, borderRadius: 60 }} src={dataIndex === '' ? require('./../../Images/semimagem.png') : Config.Url + 'Pontos/' + dataIndex} />} />
        </di>
    },
    {
      title: 'NOME',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'TIPO',
      dataIndex: 'Tipo',
      key: 'Tipo',
    },
    {
      title: 'HORA',
      dataIndex: 'Hora',
      key: 'Hora',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  const uploadButton = (
    <div style={{ width: 450, height: 80, marginBottom: 10, backgroundColor: '#eee', justifyContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
      {Loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Imagem
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      setImagem(info.file.name);
    }
  };
  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <Search
          style={{ marginLeft: 10, width: 400 }}
          placeholder="Buscar por nome"
          enterButton="Buscar"
          size="large"
          onSearch={onSearch}
        />
      </div>
      <Progress  percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Meridianos} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar meridiano" : "Novo meridiano"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar():Salvar()} >Salvar</Button>
          </Space>
        }>


        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Nome</label>
            <Input value={Nome} onChange={(e)=>setNome(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Nome" />
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Tipo</label>
            <Input value={Tipo} onChange={(e)=>setTipo(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Tipo" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Hora</label>
            <Input value={Hora} onChange={(e)=>setHora(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Hora" />
          </div>
          <div style={{ width: '48%', display: 'flex', flexDirection: 'column' }}>
            <label>Ordem</label>
            <Input value={Ordem} onChange={(e)=>setOrdem(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="01" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Upload
            name="file"
            listType="picture"
            className="avatar-uploader"
            action={Config.Url + 'UploadsPontos'}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {Imagem ? (
              <Avatar
                src={Config.Url + 'Pontos/' + Imagem}
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: 10,
                  marginBottom: 15
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>


      </Drawer>

    </div>
  );
};



export default Meridianos;
