import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress, Select } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
import { useNavigate } from 'react-router-dom';

function Lives() {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Buscar, setBuscar] = useState('');

  const [Id, setId] = useState('');
  const [Nome, setNome] = useState('');
  const [Descricao, setDescricao] = useState('');
  const [EmLive, setEmLive] = useState(false);

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setNome(Model.Nome)
    setDescricao(Model.Descricao)
    setEmLive(Model.EmLive)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setNome('')
    setDescricao('')
    setEmLive(false)
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarLives',
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };


  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'CriarLive',
      data: {
        Nome,
        Descricao,
        EmLive
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistros()
          Limpar()
          message.success('Live criado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao criar Live')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarLive',
      data: {
        Id: Id,
        Nome,
        Descricao,
        EmLive
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Live editado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar Live')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarLive',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          Limpar()
          BuscarRegistros()
          message.success('Live excluído com sucesso')
        } else {
          setLoadingPage(false);
          BuscarRegistros()
          message.success('Falha ao excluir Live')
        }
      })
  };


  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'LINK',
      dataIndex: 'CodigoLive',
      key: 'CodigoLive',
      render: (dataIndex, key) =>
        <a onClick={() => navigate('/dashboard/live/' + dataIndex)}>
          <text>
            Link
          </text>
        </a>
    },
    {
      title: 'CÓDIGO',
      dataIndex: 'CodigoLive',
      key: 'CodigoLive',
    },
    {
      title: 'TÍTULO',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'DESCRIÇÃO',
      dataIndex: 'Descricao',
      key: 'Descricao',
    },
    {
      title: 'LIVE',
      dataIndex: 'EmLive',
      key: 'EmLive',
      render: (dataIndex, key) =>
        <text>{dataIndex === true ? 'Sim' : 'Não'}</text>
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Registros} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Glossário" : "Novo Glossário"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>


        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Titulo</label>
            <Input value={Nome} onChange={(e) => setNome(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Palavra" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Descrição</label>
            <Input.TextArea value={Descricao} onChange={(e) => setDescricao(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Significado" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Em Live</label>
            <Select value={EmLive} onChange={(e) => setEmLive(e)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Significado" >
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </Select>
          </div>
        </div>

      </Drawer>

    </div>
  );
};



export default Lives;
