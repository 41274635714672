import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress, Tooltip } from 'antd';
import { CloseOutlined, CheckOutlined, FilePdfOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Comunidade() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Clientes, setClientes] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);


  async function BuscarClientes() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarUsuarioComFiltro',
      data: { Buscar: `%%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setClientes(e.result);
        } else {
          setClientes([]);
        }
      })
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarComunidadeAll'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };

  async function DisparaNotificao(Model) {
    const Message = {
      "to": Model.TokenNotificacao,
      "sound": "default",
      "title": 'Comunidade',
      "body": 'Uma nova mensagem foi postada na comunidade. Confira e deixe seu comentário'
    };

    fetch("https://servidor.acupunturapro.com/Notification", {
      method: 'POST',
      body: JSON.stringify(Message),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(e => e.json())
    .then(e => {

     console.log(e)

    });
  };

  function Notificar() {
    Clientes.filter(e => e.TokenNotificacao !== null && e.TokenNotificacao !== '')
    .map(e => {
      DisparaNotificao(e);
    });
  };

  async function Editar(Model, Status) {
    if (Status === 'Aprovado') {
      Notificar();
    };
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarComunidade',
      data: {
        Id: Model.Id,
        Status
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          BuscarRegistros()
          message.success('Publicação editado com sucesso')
        } else {
          BuscarRegistros()
          setLoadingPage(false);
          message.success('Falha ao editar publicação')
        }
      })
  };

  useEffect(() => {
    BuscarRegistros()
    BuscarClientes()
  }, [Buscar])


  function FiltrarNomde(Id) {
    const Clinete = Clientes.filter(e => e.Id === Id);
    const Nome = Clinete.map(e => e.Nome);
    return { Clinete, Nome }
  }

  const columns = [
    {
      title: 'TEXTO',
      dataIndex: 'Texto',
      key: 'Texto',

    },
    {
      title: 'LINK',
      dataIndex: 'Link',
      key: 'Link',
      render: (dataIndex,) =>
        <di>
          {dataIndex && <Button href={dataIndex} target='_blank' icon={<FilePdfOutlined />} />}
        </di>
    },
    {
      title: 'STATUS',
      dataIndex: 'Status',
      key: 'Status',
      render: (dataIndex, key) =>
        <text style={{ color: dataIndex === 'Aguardando' ? 'orange' : dataIndex === 'Aprovado' ? 'green' : 'red' }}>{dataIndex}</text>
    },
    {
      title: 'USUÁRIO',
      dataIndex: 'Usuario',
      key: 'Usuario',
      render: (dataIndex, key) =>
        <Tooltip placement="topLeft" title={<div>
          {FiltrarNomde(dataIndex)['Clinete'].map((e, index) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <text>Id Stripe: {e.Id}</text>
              <text>Nome: {e.Nome}</text>
              <text>Telefone: {e.Telefone}</text>
              <text>Whatsapp: {e.Whatsapp}</text>
              <text>Email: {e.Email}</text>
              <text>Usuário: {e.Usuario}</text>
            </div>
          ))}
        </div>} >
          <text>{FiltrarNomde(dataIndex)['Nome']}</text>
        </Tooltip>
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => Editar(key, 'Aprovado')} title='Aprovar' type="primary" shape="circle" icon={<CheckOutlined />} />
          <Button onClick={() => Editar(key, 'Reprovado')} danger title='Reprovar' type="primary" shape="circle" icon={<CloseOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      {/* <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <Search
            style={{ marginLeft: 10, width: 400 }}
            placeholder="Buscar"
            enterButton="Buscar"
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div> */}
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Registros} max columns={columns} />
      </div>

    </div>
  );
};



export default Comunidade;
