import { useState, useEffect } from 'react'
import Config from '../../Configuracoes/config';
import { Table, Button, Space, Input, Drawer, notification, message, Progress, Select } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import './style.css';
const { Search } = Input;

function Quiz() {
  const [api, contextHolder] = notification.useNotification();
  const [LoadingPage, setLoadingPage] = useState(false);
  const [Novo, setNovo] = useState(false);
  const [NovoRespostas, setNovoRespostas] = useState(false);
  const [Registros, setRegistros] = useState([]);
  const [Respostas, setRespostas] = useState([]);
  const [Buscar, setBuscar] = useState('');
  const onSearch = (value) => setBuscar(value);

  const [Id, setId] = useState('');
  const [Pergunta, setPergunta] = useState('');
  const [Resposta, setResposta] = useState('');

  const [IdResposta, setIdResposta] = useState('');
  const [Letra, setLetra] = useState('');
  const [RespostaQ, setRespostaQ] = useState('');

  function AbrirRegistro(Model) {
    setNovo(true)
    setId(Model.Id)
    setPergunta(Model.Pergunta)
    setResposta(Model.Resposta)
  };

  function AbrirRegistroResposta(Model) {
    setNovoRespostas(true)
    setIdResposta(Model.Id)
    setLetra(Model.Letra)
    setRespostaQ(Model.Resposta)
  };

  function Limpar() {
    setNovo(false)
    setId('')
    setPergunta('')
    setResposta('')
  };

  function LimparResposta() {
    setNovoRespostas(false)
    setIdResposta('')
    setLetra('')
    setRespostaQ('')
  };

  async function BuscarRegistros() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'ListarQuizFiltro',
      data: { Buscar: `%${Buscar}%` }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setLoadingPage(false);
          setRegistros(e.result);
        } else {
          setLoadingPage(false);
          setRegistros([]);
        }
      })
  };

  async function BuscarRegistrosRespostas() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarQuizResposta'
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          setRespostas(e.result);

          if(Id){
            setLoadingPage(false);
          }
        } else {
          if(Id){
            setLoadingPage(false);
          }
          setRespostas([]);
        }
      })
  };

  async function Salvar() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoQuiz',
      data: {
        Pergunta,
        Resposta
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarRegistros()
          Limpar()
          message.success('Pergunta criada com sucesso')
        } else {
          BuscarRegistros()
          message.success('Falha ao criar pergunta')
        }
      });
  };

  async function Editar() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarQuiz',
      data: {
        Id: Id,
        Pergunta,
        Resposta
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          Limpar()
          BuscarRegistros()
          message.success('Pergunta editada com sucesso')
        } else {
          BuscarRegistros()
          message.success('Falha ao editar pergunta')
        }
      })
  };

  async function Deletar(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarQuiz',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          Limpar()
          BuscarRegistros()
          message.success('Pergunta excluída com sucesso')
        } else {
          BuscarRegistros()
          message.success('Falha ao excluir pergunta')
        }
      })
  };

  async function SalvarResposta() {
    setLoadingPage(true);
    axios({
      method: 'post',
      url: Config.Url + 'NovoQuizResposta',
      data: {
        Letra,
        Quiz:Id,
        Resposta:RespostaQ
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarRegistrosRespostas()
          LimparResposta()
          message.success('Resposta criada com sucesso')
        } else {
          BuscarRegistrosRespostas()
          message.success('Falha ao criar resposta')
        }
      });
  };

  async function EditarResposta() {
    setLoadingPage(true);
    axios({
      method: 'put',
      url: Config.Url + 'EditarQuizResposta',
      data: {
        Id: IdResposta,
        Letra,
        Resposta:RespostaQ
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarRegistrosRespostas()
          LimparResposta()
          message.success('Resposta editada com sucesso')
        } else {
          BuscarRegistrosRespostas()
          message.success('Falha ao editar resposta')
        }
      })
  };

  async function DeletarResposta(Model) {
    setLoadingPage(true);
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarQuizResposta',
      data: {
        Id: Model.Id,
      }
    }).then(e => e.data)
      .then(e => {
        if (e.sucesso) {
          BuscarRegistrosRespostas()
          LimparResposta()
          message.success('Resposta excluída com sucesso')
        } else {
          BuscarRegistrosRespostas()
          message.success('Falha ao excluir resposta')
        }
      })
  };

  useEffect(() => {
    BuscarRegistros()
  }, [Buscar])

  useEffect(() => {
    BuscarRegistrosRespostas()
  }, [])

  function MensagemPedirSenha(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Deletar(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }

  function MensagemPedirSenhaResposta(Model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(Model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => DeletarResposta(Model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: Model.Id
    });
  }


  const columns = [
    {
      title: 'PERGUNTA',
      dataIndex: 'Pergunta',
      key: 'Pergunta',
    },
    {
      title: 'RESPOSTA',
      dataIndex: 'Resposta',
      key: 'Resposta',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistro(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenha(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  const columnsRespostas = [
    {
      title: 'LETRA',
      dataIndex: 'Letra',
      key: 'Letra',
    },
    {
      title: 'RESPOSTA',
      dataIndex: 'Resposta',
      key: 'Resposta',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirRegistroResposta(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemPedirSenhaResposta(key)} danger title='Excluir' type="primary" shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" onClick={() => setNovo(!Novo)}>ADICIONAR</Button>
        </Space>
        <div>
          <Search
            style={{ marginLeft: 10, width: 400 }}
            placeholder="Buscar"
            enterButton="Buscar"
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div>
      <Progress percent={LoadingPage ? 0 : 100} />
      <div className='ContainerTable'>
        <Table dataSource={Registros} max columns={columns} />
      </div>

      <Drawer
        title={Id !== '' ? "Editar Quiz" : "Novo Quiz"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), Limpar()]}>Fechar</Button>
            <Button type="primary" onClick={() => Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>


        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Pergunta</label>
            <Input value={Pergunta} onChange={(e) => setPergunta(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Pergunta" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Resposta</label>
            <Select value={Resposta} onSelect={(e) => setResposta(e)} size='large' style={{ width: '100%' }} placeholder="Resposta">
              <option value="" >Resposta</option>
              {Respostas.filter(e=>e.Quiz === Id).map((e, index) => (
                <option key={index} value={e.Letra} >( {e.Letra} ) - {e.Resposta}</option>
              ))}
            </Select>
          </div>
        </div>



        {Id && <div className='ContainerTableNav'>
          <text>Respostas</text>
          <Space>
            <Button style={{ height: 30 }} type="primary" onClick={() => setNovoRespostas(true)}>ADICIONAR</Button>
          </Space>
        </div>}

        {Id &&
          <div className='ContainerTable'>
            <Table dataSource={Respostas.filter(e=>e.Quiz === Id)} max columns={columnsRespostas} />
          </div>}

      </Drawer>


      <Drawer
        title={IdResposta !== '' ? "Editar Resposta" : "Nova Resposta"}
        closable={false}
        width={480}
        placement="right"
        open={NovoRespostas}
        extra={
          <Space>
            <Button onClick={() => [setNovoRespostas(false), LimparResposta()]}>Fechar</Button>
            <Button type="primary" onClick={() => IdResposta !== '' ? EditarResposta() : SalvarResposta()} >Salvar</Button>
          </Space>
        }>


        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Letra</label>
            <Input value={Letra} onChange={(e) => setLetra(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Letra" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <label>Resposta</label>
            <Input value={RespostaQ} onChange={(e) => setRespostaQ(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Resposta" />
          </div>
        </div>

      </Drawer>

    </div>
  );
};



export default Quiz;
