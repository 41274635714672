const COR1 = '#3c5270';
const COR2 = '#5c7996';
const Primary = '#3c5270';
const Secundary = '#5c7996';
const Preto = '#000000';
const Branco = '#FFFFFF';
const Tercery = '#ebedef';
const Vermelho = '#F00';
const CinzaClaro = '#EEEEEE';
const Azul = 'blue';
const Verde = 'green';
const CinzaEscuro = '#a7a7a7';
const BRANCO = "#FFFFFF";
const PRETO = "#000000";
const Url = process.env.REACT_APP_URL_API || 'https://servidor.acupunturapro.com/';
const UrlApiCobranca = process.env.REACT_APP_URL_API_COBRANCA || 'https://cobrefacil.acupunturapro.com/';        // pmac : verificar url correta.

export default {COR1, COR2, Primary, Preto, Branco, Azul, Vermelho, CinzaClaro, Tercery, Secundary, CinzaEscuro, Verde, BRANCO, PRETO, Url, UrlApiCobranca};